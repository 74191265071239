import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { TooltipHeader } from "../../ui/TooltipHeader";
import { scrollToBlock } from "../../pages/CasesPage/CasePage";
import { LinkMessenger } from "../../ui";
const links = {
  ru: [
    { name: "Приложения", path: "/ru" },
    { name: "Автоматизация процессов", path: "/automation/ru" },
    { name: "Кейсы", path: "/portfolio/ru" },
    { name: "Контакты", id: "contact-block-new" },
  ],

  en: [
    { name: "Applications", path: "/en" },
    { name: "Process Automation", path: "/automation/en" },
    { name: "Cases", path: "/portfolio/en" },
    { name: "Contacts", id: "contact-block-new" },
  ],
};

const tooltipData = {
  en: [
    { name: "foodtech", path: "/restaurant/en" },
    { name: "communal", path: "/communal/en" },
    { name: "flutter", path: "/flutter/en" },
    { name: "fitness", path: "/health/en" },
  ],
  ru: [
    { name: "фудтех", path: "/restaurant/ru" },
    { name: "ЖКХ", path: "/communal/ru" },
    { name: "флаттер", path: "/flutter/ru" },
    { name: "фитнес", path: "/health/ru" },
  ],
};

const BaseHeader = ({
  lang,
  page,
  setHeightHeader,
  isNotification = false,
  is404 = false,
}) => {
  const [isScrollY, setIsScrollY] = useState(isNotification);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const ref = useRef();

  const onDragEvent = useCallback(
    () => (e) => {
      e.preventDefault();
    },
    []
  );

  const onScrollPage = useCallback(() => {
    setIsScrollY(window.scrollY >= 90);
  }, []);

  useEffect(() => {
    document.addEventListener("dragover", onDragEvent, false);
    document.addEventListener("drop", onDragEvent, false);
    document.addEventListener("scroll", onScrollPage);

    return () => {
      document.removeEventListener("dragover", onDragEvent);
      document.removeEventListener("drop", onDragEvent);
      document.removeEventListener("scroll", onScrollPage);
    };
  }, [onDragEvent, onScrollPage]);

  useEffect(() => {
    if (ref?.current && setHeightHeader) {
      setHeightHeader(ref.current.clientHeight);
    }
  }, [ref, setHeightHeader]);

  return (
    <header
      ref={ref}
      className={`main-header navbar navbar-sticky navbar-expand-lg navbar-dark ${
        isScrollY || isNotification
          ? "navbar-sticky-moved-up navbar-sticky-transitioned navbar-sticky-on"
          : ""
      } ${isShowSidebar ? "active" : ""}
      ${isOpenDropDown && !isShowSidebar ? "shadow-bottom" : ""}
      ${isNotification ? "notification-header" : ""}
      ${is404 ? "black-header" : ""}
      `}
    >
      <div className="container position-relative">
        <Link className="navbar-brand" to={`/main/${lang}`}>
          <div className="navbar-brand-regular my-auto row justify-content-center align-items-center">
            <img
              width={75}
              height={76}
              className="ml-3 brand-logo"
              src="/img/ITWEBFOX WHITE_TXT2FIX_NEW.svg"
              alt="brand-logo"
            />
          </div>
          <div className="navbar-brand-sticky my-auto row justify-content-center align-items-center">
            <img
              width={75}
              height={76}
              className="ml-3 brand-logo"
              src="/img/ITWEBFOX_FIX_BLACK_TXT.svg"
              alt="sticky brand-logo"
            />
          </div>
        </Link>
        {!isNotification && (
          <button
            className="navbar-toggler d-lg-none"
            type="button"
            data-toggle="navbarToggler"
            aria-label="Toggle navigation"
            onClick={() => setIsShowSidebar(!isShowSidebar)}
          >
            <span className={`navbar-toggler-icon`} />
          </button>
        )}
        <div className="navbar-inner">
          {!isNotification && (
            <>
              <button
                className="navbar-toggler d-lg-none"
                type="button"
                data-toggle="navbarToggler"
                aria-label="Toggle navigation"
                onClick={() => setIsShowSidebar(!isShowSidebar)}
              >
                <span
                  className={`navbar-toggler-icon ${
                    isShowSidebar ? "active" : ""
                  }`}
                />
              </button>
              <nav className="navigation">
                <Link
                  to={links[lang][0].path}
                  className={`nav-item ${page === "general" ? "active" : ""}`}
                  onMouseLeave={() => setIsOpenDropDown(false)}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <span onMouseOver={() => setIsOpenDropDown(true)}>
                    {links[lang][0].name}
                  </span>
                  {isOpenDropDown && (
                    <TooltipHeader
                      data={tooltipData[lang]}
                      isScroll={isScrollY}
                      page={page}
                    />
                  )}
                </Link>
                <div className="list-project">
                  <Link
                    to={links[lang][0].path}
                    className={`nav-item ${page === "general" ? "active" : ""}`}
                  >
                    {links[lang][0].name}
                  </Link>
                  {tooltipData[lang].map((el) => (
                    <Link
                      to={el.path}
                      key={`item-link-${el.path}`}
                      className={`nav-item ${
                        el?.path?.includes(page) ? "active" : ""
                      }`}
                    >
                      {el.name}
                    </Link>
                  ))}
                </div>
                {links[lang].slice(1).map((el, idx) =>
                  el.path ? (
                    <Link
                      to={
                        idx === 1 &&
                        page !== "main" &&
                        page !== "general" &&
                        page !== "case" &&
                        page !== "portfolio"
                          ? el.path +
                            `${
                              page === "flutter" || page === "automation"
                                ? `?tagcase=`
                                : `?tag=`
                            }` +
                            `${
                              page === "communal"
                                ? "utilities"
                                : page === "health"
                                ? "lifestyle"
                                : page
                            }`
                          : el.path
                      }
                      onClick={() => {
                        page === "portfolio" &&
                          el.path === "/portfolio/ru" &&
                          window.location.reload();
                      }}
                      key={`item-link-${idx}`}
                      className={`nav-item ${
                        el?.path?.includes(page) ? "active" : ""
                      }`}
                    >
                      {el.name}
                    </Link>
                  ) : (
                    <div
                      key={`item-link-${idx}`}
                      className={`nav-item ${
                        el?.id.includes(page) ? "active" : ""
                      }`}
                      onClick={() => {
                        page === "main"
                          ? scrollToBlock(
                              "contacts",
                              ref.current.clientHeight ?? 0
                            )
                          : scrollToBlock(el.id, ref.current.clientHeight ?? 0);
                      }}
                    >
                      {el.name}
                    </div>
                  )
                )}
                <a href="tel:+79288542462" className="nav-item">
                  +7 (928) 854-24-62
                </a>
              </nav>
              <div className="block-btn">
                {/* <a
                  className="nav-item link-messenger"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://t.me/ITfoxweb"
                  aria-label="Our telegram"
                >
                  <img
                    src={`/img/telegram${
                      isScrollY || isShowSidebar ? "-new" : "-white"
                    }.svg`}
                    alt=""
                  />
                  <span>Telegram</span>
                </a> */}

                <LinkMessenger
                  className="nav-item "
                  link="https://t.me/ITfoxweb"
                  img={`/img/telegram${
                    isScrollY || isShowSidebar ? "-new" : "-white"
                  }.svg`}
                  text="Telegram"
                />
                <LinkMessenger
                  className="nav-item"
                  link="https://api.whatsapp.com/send/?phone=79288542462"
                  img={`/img/whatsapp${
                    isScrollY || isShowSidebar ? "" : "-white"
                  }.svg`}
                  text="WhatsApp"
                />
                {/* <a
                  className="nav-item link-messenger"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://api.whatsapp.com/send/?phone=79288542462"
                  aria-label="Our whatsapp"
                >
                  <img
                    src={`/img/whatsapp${
                      isScrollY || isShowSidebar ? "" : "-white"
                    }.svg`}
                    alt=""
                  />
                  <span>WhatsApp</span>
                </a> */}
              </div>
            </>
          )}
          {/* убрать проверку когда в кейсы зальют английский текст */}
          {
            <Link
              className="nav-item lang"
              to={
                page === "restorant"
                  ? "/restorant/" + (lang === "ru" ? "en" : "ru")
                  : page === "health"
                  ? "/health/" + (lang === "ru" ? "en" : "ru")
                  : page === "communal"
                  ? "/communal/" + (lang === "ru" ? "en" : "ru")
                  : page === "main"
                  ? "/main/" + (lang === "ru" ? "en" : "ru")
                  : page === "notification"
                  ? "/notification/" + (lang === "ru" ? "en" : "ru")
                  : lang === "ru"
                  ? "en"
                  : "ru"
              }
            >
              {lang === "ru" ? "en" : "ru"}
            </Link>
          }
        </div>
      </div>
    </header>
  );
};

export default BaseHeader;
