import React, { useMemo, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import {
  BaseModal,
  Budget,
  Checkbox,
  InputField,
  Spinner,
  UploadFile,
} from "../../../ui";
import { sendContacts } from "../../../tools/sendContacts";
import { validateFieldPhone, validateFieldText } from "../../../ui/InputField";

export const data = {
  en: {
    heading: "Submit a request",
    submitText: "Leave a request",
    name: "Name",
    phone: "Phone",
    message: "Message",
    agree: "Non Disclosure Agreement",
    privacy:
      "I agree with the privacy policy and the use of the requested data",
    sendText: "We have received your message",
    sendError: "An error has occurred! Try later.",
    button: "Send",
    errorName: "Name must not contain numbers",
    errorPhone: "The phone number was entered incorrectly",
    errorBudget: "Choose a budget",
    errPrivacy: "Set this checkbox",
    necessarily: "Please fill in this field",
    upload: "Choose File",
    notFile: "File not selected",
    limitation: "The volume of uploaded files should not exceed 10Mb",
    budget: {
      name: "Budget",
      values: ["5K to 10K, $", "10k to 30K, $", "30K to 100K, $", "over $100K"],
    },
  },
  ru: {
    heading: "Оставьте заявку",
    submitText: "Оставить заявку",
    name: "Имя",
    phone: "Телефон",
    message: "Сообщение",
    sendText: "Ваша заявка принята",
    sendError: "Произошла ошибка! Попробуйте позже.",
    button: "Отправить",
    errorName: "Имя должно содержать только буквы и пробелы",
    errorBudget: "Выберите бюджет",
    errPrivacy: "Установите этот флажок",
    upload: "Выберите файл",
    notFile: "Файл не выбран",
    limitation: "Объём загружаемых файлов не должен превышать 10Мб",
    errorPhone: "Номер телефона введен не верно",
    necessarily: "Заполните это поле",
    agree: "Согласие о неразглашении (NDA)",
    privacy:
      "Я согласен c политикой конфиденциальности и использования запрашиваемых данных",
    budget: {
      name: "Бюджет",
      values: [
        "от 500 тыс. до 1 млн. ₽",
        "от 1 млн. до 3 млн. ₽",
        "от 3 млн. до 10 млн. ₽",
        "от 10 млн. ₽",
      ],
    },
  },
};

const validateForm = (form, setForm, lang) => {
  console.log(data[lang].errMsgPhone);
  setForm({
    ...form,
    errMsgName: validateFieldText(
      form.name,
      form.name ? data[lang].errorName : data[lang].necessarily
    ),
    errMsgPhone: validateFieldPhone(
      form.phone,
      form.phone ? data[lang].errorPhone : data[lang].necessarily
    ),
    errMsgBudget: form.budget ? "" : data[lang].errorBudget,
    errMsgPrivacy: form.privacy ? "" : data[lang].errPrivacy,
  });
};

const initStateForm = {
  name: "",
  phone: "",
  message: "",
  isSending: false,
  errMsgName: "",
  errMsgPhone: "",
  errMsgBudget: "",
  errMsgPrivacy: "",
  privacy: false,
  nda: false,
  file: "",
  fileName: "",
  budget: "",
};

export const RequestSection = ({ lang, page, nameCase }) => {
  const history = useHistory();
  const [form, setForm] = useState(initStateForm);
  const [isShowModal, setIsShowModal] = useState(false);

  const isValidate = useMemo(
    () =>
      form.name &&
      form.phone &&
      form.budget &&
      form.privacy &&
      !form.isSending &&
      !form.errMsgPhone &&
      !form.errMsgName,
    [
      form.name,
      form.phone,
      form.budget,
      form.privacy,
      form.isSending,
      form.errMsgPhone,
      form.errMsgName,
    ]
  );

  const redirectToNotificationPage = () =>
    history.push(`/notification/${lang}`);

  const hiddenShowModal = () => setIsShowModal(!isShowModal);

  const submitData = async (e) => {
    e.preventDefault();
    if (!isValidate) {
      validateForm(form, setForm, lang);
      return;
    }
    setForm({ ...form, isSending: true });
    const { name, phone, message, file, budget, fileName, privacy, nda } = form;

    await sendContacts(
      {
        name,
        phone,
        message,
        budget,
        file,
        fileName,
        tag: `финальная форма ${
          page === "case" ? nameCase : page === "general" ? "mobile" : page
        }`,
        privacy,
        nda,
      },
      redirectToNotificationPage,
      hiddenShowModal
    );

    setForm(initStateForm);
  };

  return (
    <div className="base-section request" id="contacts">
      <div className="container">
        <div className="title">{data[lang].heading}</div>
        <div className="shadow-form" />
        <form className="form" onSubmit={submitData} noValidate>
          <div className="block-fields">
            <InputField
              value={form.name}
              onChangeHandler={(val) => setForm({ ...form, name: val })}
              required={true}
              placeholder={data[lang].name}
              handlerOnBlur={(val) => setForm({ ...form, errMsgName: val })}
              errorMessage={form.errMsgName}
              validateMessage={
                form.name ? data[lang].errorName : data[lang].necessarily
              }
            />
            <InputField
              value={form.phone}
              onChangeHandler={(val) => setForm({ ...form, phone: val })}
              required={true}
              placeholder={data[lang].phone}
              handlerOnBlur={(val) => setForm({ ...form, errMsgPhone: val })}
              type="tel"
              errorMessage={form.errMsgPhone}
              validateMessage={
                form.phone ? data[lang].errorPhone : data[lang].necessarily
              }
            />
          </div>
          <InputField
            value={form.message}
            onChangeHandler={(val) => setForm({ ...form, message: val })}
            placeholder={data[lang].message}
            type="text"
            isTextarea={true}
          />
          <UploadFile
            textBtn={data[lang].upload}
            placeholder={data[lang].notFile}
            fileName={form.fileName}
            textInfo={data[lang].limitation}
            setFile={(file, fileName) => setForm({ ...form, file, fileName })}
          />
          <Budget
            data={data[lang].budget}
            value={form.budget}
            setValue={(budget) =>
              setForm({ ...form, errMsgBudget: "", budget })
            }
            errorMessage={form.errMsgBudget}
          />
          <div className="checkboxes">
            <Checkbox
              label={data[lang].agree}
              isChecked={form.nda}
              onChange={(nda) => setForm({ ...form, nda })}
              name="nda"
            />
            <Checkbox
              name="privacy-police"
              label={data[lang].privacy}
              isChecked={form.privacy}
              onChange={(privacy) =>
                setForm({
                  ...form,
                  errMsgPrivacy: privacy ? "" : data[lang].errPrivacy,
                  privacy,
                })
              }
              errorMessage={form.errMsgPrivacy}
            />
          </div>
          <button className="btn-action" type="submit">
            {form.isSending ? <Spinner /> : <span>{data[lang].button}</span>}
          </button>
        </form>
        <div className="shadow-form" />
      </div>
      <BaseModal
        open={isShowModal}
        close={hiddenShowModal}
        msg={data[lang].sendError}
        img={"/img/error.svg"}
      />
    </div>
  );
};
