import React, { Component } from "react";
import TagBlock from "../tag/TagBlock";
import TagRedBlock from "../tag/TagRedBlock";
import CasesBlockNew from "../caseBlock/CasesBlockNew";
const initData = (lang) => window?.cases[lang];

export const tags = [
  {
    text: {
      ru: "#стартапы",
      en: "#startups",
    },
    url: "startup",
    img: "/img/start-up.svg",
  },
  {
    text: {
      ru: "#финтех",
      en: "#fintech",
    },
    url: "fintech",
    img: "/img/Fintech.svg",
  },
  {
    text: {
      ru: "#фудтех",
      en: "#foodtech",
    },
    url: "restaurant",
    img: "/img/Foodtech.svg",
  },
  {
    text: {
      ru: "#лайфстайл",
      en: "#lifestyle",
    },
    url: "lifestyle",
    img: "/img/life-style.svg",
  },
  {
    text: {
      ru: "#жкх",
      en: "#housing and communal services",
    },
    url: "utilities",
    img: "/img/jkh.svg",
  },
];
class CasesSection extends Component {
  state = {
    langData: [],
    data: [],
    tagsSecondLevel: [],
    currenttagsSecondLevel: null,
    currentFilters: "",
    currentUrl: "",
  };

  getSearch() {
    const params = new URLSearchParams(window.location.search);

    const getSearch = params.get("tag");
    const getSearchCase = params.get("tagcase");
    this.setState({
      langData: initData(this.props.lang),
      data: initData(this.props.lang),
    });
    if (getSearch === null) {
      this.setState({
        currentFilters: "",
      });
    }
    const urlTag = tags.find((tag) => tag.url === getSearch);
    this.setState({
      currentFilters: urlTag?.text?.[this.props.lang],
      currentUrl: getSearch,
    });
    setTimeout(() => {
      this.choiceCases();
      this.choiceFiltersSecond();
      const urlTagCase = this.state.tagsSecondLevel.find(
        (item) => item.url === getSearchCase
      );
      this.choiceCurrenttagsSecondLevel(urlTagCase);
      this.choiceCasesLevel();
    }, 1000);
  }

  componentDidMount() {
    this.getSearch();
  }

  choiceFilters = (tag) => {
    this.setState((prevState) => {
      if (prevState.currentFilters !== tag.text?.[this.props.lang]) {
        return {
          currentFilters: tag.text?.[this.props.lang],
        };
      }
      if (prevState.currentFilters === tag.text?.[this.props.lang]) {
        return {
          currentFilters: "",
        };
      }
    });
  };

  choiceCases = () => {
    this.setState((prevState) => {
      if (prevState.currentFilters?.length > 0) {
        return {
          data: this.state.langData.filter(
            (item) => prevState.currentFilters === item.tag
          ),
        };
      }
      if (prevState.currentFilters === "") {
        return {
          data: this.state.langData,
        };
      }
    });
  };

  choiceCasesLevel = () => {
    this.setState((prevState) => {
      if (prevState.currenttagsSecondLevel && prevState.currentFilters) {
        return {
          data: this.state.langData.filter(
            (item) =>
              prevState.currentFilters === item.tag &&
              item.tagsCase.find(
                (item) => item.name === prevState.currenttagsSecondLevel.name
              )
          ),
        };
      }
      if (prevState.currenttagsSecondLevel && !prevState.currentFilters) {
        return {
          data: this.state.langData.filter((item) =>
            item.tagsCase.find(
              (item) => item.name === prevState.currenttagsSecondLevel.name
            )
          ),
        };
      }
      if (!prevState.currenttagsSecondLevel && prevState.currentFilters) {
        return {
          data: this.state.langData.filter(
            (item) => prevState.currentFilters === item.tag
          ),
        };
      }
      if (!prevState.currenttagsSecondLevel && !prevState.currentFilters) {
        return {
          data: this.state.langData,
        };
      }
    });
  };

  choiceFiltersSecond(value) {
    this.setState((prevState) => {
      const t = prevState.data.map((item) => item.tagsCase).flat(1);
      return {
        tagsSecondLevel: [
          ...new Set(t.map((item) => JSON.stringify(item))),
        ].map((item) => JSON.parse(item)),
      };
    });
  }

  choiceCurrenttagsSecondLevel = (tag) => {
    this.setState((prevState) => {
      if (prevState.currenttagsSecondLevel?.name !== tag?.name) {
        return {
          currenttagsSecondLevel: tag,
        };
      }
      if (prevState.currenttagsSecondLevel?.name === tag?.name) {
        return {
          currenttagsSecondLevel: "",
        };
      }
    });
  };

  render() {
    return (
      <section id="cases-new" className="section py-3">
        <span id="cases-label-new"></span>
        <div className="container">
          <div className="tag-block-wrap h-100 mt-1">
            <div className="mt-2 tag-ul row m-0">
              {tags.map((tag, index) => (
                <TagBlock
                  key={index}
                  lang={this.props.lang}
                  class={
                    this.state.currentFilters === tag.text?.[this.props.lang]
                      ? "active"
                      : ""
                  }
                  tag={tag}
                  linkUrl={
                    this.state.currentFilters !== tag.text?.[this.props.lang]
                      ? `/portfolio/${this.props.lang}/?tag=${tag.url}`
                      : `/portfolio/${this.props.lang}/?${
                          this.state.currenttagsSecondLevel
                            ? "&tagcase=" +
                              this.state.currenttagsSecondLevel.url
                            : ""
                        }`
                  }
                  onClick={() => {
                    this.choiceCurrenttagsSecondLevel(null);
                    this.choiceFilters(tag);
                    this.choiceCases();
                    this.choiceFiltersSecond();
                    this.choiceCasesLevel();
                  }}
                />
              ))}
            </div>
            <hr />
            <div className="mt-2 mb-2 tag-ul row m-0">
              {this.state.tagsSecondLevel &&
                this.state.tagsSecondLevel.map((t, index) => (
                  <TagRedBlock
                    key={index}
                    lang={this.props.lang}
                    tag={t}
                    class={
                      this.state.currenttagsSecondLevel?.name === t.name
                        ? "active"
                        : ""
                    }
                    linkUrl={
                      this.state.currenttagsSecondLevel?.name !== t.name
                        ? `/portfolio/${this.props.lang}/?tagcase=${t.url}${
                            this.state.currentFilters
                              ? "&tag=" +
                                tags.find(
                                  (tag) =>
                                    tag.text?.[this.props.lang] ===
                                    this.state.currentFilters
                                ).url
                              : ""
                          }`
                        : `/portfolio/${this.props.lang}/?${
                            this.state.currentFilters
                              ? "&tag=" +
                                tags.find(
                                  (tag) =>
                                    tag.text.ru === this.state.currentFilters
                                ).url
                              : ""
                          }`
                    }
                    onClick={() => {
                      this.choiceCases();
                      this.choiceCurrenttagsSecondLevel(t);
                      this.choiceCasesLevel();
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="row">
            {this.state.data?.map((element, index) => (
              <CasesBlockNew
                key={index}
                title={element?.title}
                idCase={element?.id}
                main={false}
                image={element?.image}
                text={element?.text}
                tagsCase={element?.tagsCase}
                lang={this.props.lang}
                page={this.props.page}
                currenttagsSecondLevel={this.state.currenttagsSecondLevel}
                onClick={(value) => {
                  this.choiceCurrenttagsSecondLevel(value);
                  this.choiceCasesLevel();
                }}
              ></CasesBlockNew>
            ))}
            {this.state.data.length === 0 && (
              <div className="mb-5 mt-5">
                По заданным параметрам кейсов нет…
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
export default CasesSection;
