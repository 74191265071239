import React from "react";

export const ListMenu = ({ className, url, text }) => {
  return (
    <li>
      <a href={url} className={className}>
        {text}
      </a>
    </li>
  );
};
