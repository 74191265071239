import React from "react";
import "./style.scss";

const data = {
  en: {
    paragraphOne:
      "ItFox develops mobile and web applications, loyalty systems, web services and automates processes from 2019.",
    paragraphTwo:
      "Our developments win Russian and international competitions, and also bring measurable benefits to business: in customers, cost reduction or increased profits.",
    title: "About the company",
    shortInfo: [
      { count: "50+", transcript: "completed projects" },
      { count: "40+", transcript: "professionals" },
      { count: "5+", transcript: "years of experience" },
    ],
    shortDescription:
      "ItFox is a full-service digital- agency with in-house product analytics, design, web and mobile development",
    subTitle: "Industry specialization",
    specializations: [
      { icon: "/img/fintech.webp", name: "Fintech" },
      { icon: "/img/flutter.webp", name: "Development on flutter" },
      { icon: "/img/foodtech.webp", name: "Foodtech" },
      { icon: "/img/production.webp", name: "Production" },
      { icon: "/img/startup.webp", name: "StartUp" },
    ],
  },
  ru: {
    paragraphOne:
      "ItFox разрабатывает мобильные и веб-приложения, личные кабинеты, системы лояльности, веб-сервисы и автоматизирует процессы с 2019 года.",
    paragraphTwo:
      "Наши разработки выигрывают российские и международные конкурсы, а также приносят измеримую пользу бизнесу: в клиентах, сокращении издержек или увеличении прибыли.",
    title: "О компании",
    shortInfo: [
      { count: "50+", transcript: "выполненных проектов" },
      { count: "40+", transcript: "профессионалов" },
      { count: "5+", transcript: "лет опыта" },
    ],
    shortDescription:
      "ItFox — digital-агенство полного цикла, с собственной продуктовой аналитикой, дизайном, веб- и мобильной разработкой",
    subTitle: "Отраслевая специализация",
    specializations: [
      { icon: "/img/fintech.webp", name: "Финтех" },
      { icon: "/img/flutter.webp", name: "Разработка на flutter" },
      { icon: "/img/foodtech.webp", name: "Фудтех" },
      { icon: "/img/production.webp", name: "Производство" },
      { icon: "/img/startup.webp", name: "StartUp" },
    ],
  },
};

export const AboutCompanySection = ({ lang }) => {
  return (
    <section className="base-section" id="about-company">
      <div className="container">
        <div className="description">{data[lang].paragraphOne}</div>
        <div className="description">{data[lang].paragraphTwo}</div>
        <div className="about-us">
          <div className="title">{data[lang].title}</div>
          <div className="wrapper-content">
            <div className="list-short-info">
              {data[lang].shortInfo.map((el, idx) => (
                <div className="short-info" key={`short-info-${idx}`}>
                  <span className="count">{el.count}</span>
                  <span className="transcript">{el.transcript}</span>
                </div>
              ))}
            </div>
            <div className="short-description">
              {data[lang].shortDescription}
            </div>
            <div className="specializations">
              <div className="subtitle">{data[lang].subTitle}</div>
              <div className="list-specializations">
                {data[lang].specializations.map((el, idx) => (
                  <div
                    className="specialization-item"
                    key={`specialization-item-${idx}`}
                  >
                    <img width="52" height="52" src={el.icon} alt="" />
                    <span className="name">{el.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
